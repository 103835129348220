body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1, h2, h3, h4, h5, h6{
  font-family: "Inter", sans-serif;
}
p{
  font-family: "Poppins", sans-serif;
}
/* TEMPLATE STYLES */

.btn-primary {
  font-family: "Poppins", sans-serif;
  border: 1px solid #2F5241;
  padding: 0.7rem;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  color: #2F5241 !important;
  font-weight: 700;
  margin: 0 0.5rem;
  height: fit-content;
  -webkit-appearance: none;
  text-align: center;
  max-width: 150px;
  max-height: 25px;
  transition: background-color 0.5s, color 0.5s ease;
}
@media screen and (max-width: 867px) {
  .btn-primary {
    font-size: 12px;
  }
}
.btn-primary:hover {
  background-color: #2F5241;
  color: #fff !important;
}
.ghost {
  background-color: transparent;
  color: #2F5241;
}
.ghost:hover {
  background-color: #2F5241;
  color: white;
}
.flex_center_space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-column_center_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.theme-link{
    color: #2f5241;
    font-family: "Poppins", sans-serif;
    text-decoration: underline;
    font-weight: 700;
text-transform: uppercase;
cursor:pointer;
font-size: clamp(12px, 3vw, 16px);
}

.toScaleImage{
  transition: transform 0.2s ease;
}
.toScaleImage:hover {
  transform: scale(1.1);  
}

@media screen and (min-width: 867px) {
  .mobile-only{
    display: none;
    }
  }
  .box {
    opacity: 0;
  }
  .box.fade-active{
    opacity: 1;
    transition: opacity 1s ease-in;
      }
   .side-box img{
    transform: translateX(-120%);
  }
  .side-box div{
    transform: translateX(120%);
  }
  .side-box-reversed img{
    transform: translateX(120%);
  }
  .side-box-reversed div{
    transform: translateX(-120%);
  }
  .side-box-contact .contact-info-container{
    transform: translateX(-120%);
  }
  .side-box-contact .contact-form-container{
    transform: translateX(120%);
  }
  .side-box.fade-active img,
  .side-box-reversed.fade-active div,
  .side-box-reversed.fade-active img{
    transform: translateX(0);
    transition: transform 1s ease-in;
  }
      .side-box.fade-active div{
        transform: translateX(0);
        transition: transform 1s ease-in;
      }  
      .side-box-contact.fade-active .contact-info-container{
        transform: translateX(0);
        transition: transform 1s ease-in;
      }
      .side-box-contact.fade-active .contact-form-container{
        transform: translateX(0);
        transition: transform 1s ease-in;
      }
      .half-box.bottom-container{
        background-size: 0 !important;  
        }
        .half-box.bottom-container div{
          transform: translateX(120%);
        }
        .half-box div{
          transform: translateX(120%);
        }
        .half-box.bottom-container div:hover > .half-box.bottom-container{
          opacity: 0.2;
        }
             
      .half-box.fade-active.bottom-container{
        background-size: contain !important;  
        transition: background-size 1s ease-in;
        }
        .half-box.fade-active div{
          transform: translateX(0);
          transition: transform 1s ease-in;
        } 